.row-actividad-card {
  width: 70% !important;
  margin: 20px auto !important;
}

.foto-actividad-card {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover!important;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.div-titulo-actividad-card {
  height: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  min-height: 100px !important;
  padding: 10px !important;
}

.div-ver-mas-actividad-card {
  height: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  min-height: 100px !important;
  padding: 10px !important;
}

.titulo-actividad-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  margin: unset !important;
  line-height: 25px !important;
}

.ver-mas-actividad-card {
  background: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: unset !important;
  width: 150px !important;
  transition: 0.2s all ease-out !important;
}

.ver-mas-actividad-card:hover {
  color: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

.modal-actividad-card .ant-modal-content {
  background: transparent !important;
  box-shadow: unset !important;
}

.modal-actividad-card .ant-modal-content .ant-modal-footer {
  padding: unset !important;
  text-align: unset !important;
  background: unset !important;
  border-top: unset !important;
  border-radius: unset !important;
}

.modal-actividad-card .slick-dots li.slick-active button {
  background: rgb(244, 220, 43, 1) !important;
}

.modal-actividad-card .slick-dots li button {
  background: black !important;
}

@media only screen and (max-width: 1000px) {
  .row-actividad-card {
    width: 90% !important;
  }

  .div-titulo-actividad-card {
    align-items: center !important;
  }

  .div-ver-mas-actividad-card {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .row-actividad-card {
    width: 90% !important;
  }

  .div-titulo-actividad-card {
    align-items: center !important;
  }

  .div-ver-mas-actividad-card {
    align-items: flex-start !important;
  }
}
