.error-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}

.error-page img {
  height: 50% !important;
  width: 50% !important;
}
/* 
.nodata {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.nodata img {
  height: 50% !important;
  width: 50% !important;
} */
