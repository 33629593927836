@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
/* ================ HTML ================ */
@font-face {
  font-family: Carlito;
  src: url(/static/media/Carlito-Regular.81a99c7e.ttf);
}

@font-face {
  font-family: CarlitoBold;
  src: url(/static/media/Carlito-Bold.2916a998.ttf);
}

@font-face {
  font-family: GothamBold;
  src: url(/static/media/Gotham-Bold.8c419f68.ttf);
}

@font-face {
  font-family: Gotham;
  src: url(/static/media/Gotham-Light.59aa4004.otf);
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(34, 47, 75, 1) !important;
}
::-webkit-scrollbar-track {
  background: rgb(244, 220, 43, 1) !important;
}

.hide {
  display: none !important;
}

.visible {
  display: block !important;
}

/* ================ SECCIONES ================ */
.content {
  text-align: center !important;
  width: 75% !important;
  margin: auto !important;
  position: relative !important;
}

/* ================ ANTD ================ */
/* Card */
.ant-card {
  text-align: center !important;
}

/* Row */
.ant-row {
  justify-content: center !important;
}

/* Skeleton */
.ant-skeleton-image {
  background: unset !important;
}


/* Modal */
.ant-modal-wrap {
  z-index: 1100 !important;
}

/* ================ MEDIA QUERY ================ */
@media only screen and (max-width: 1000px) {
  .content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .content {
    width: 90% !important;
  }
}

.titulo-actividades-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.row-actividad-card {
  width: 70% !important;
  margin: 20px auto !important;
}

.foto-actividad-card {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover!important;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.div-titulo-actividad-card {
  height: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  min-height: 100px !important;
  padding: 10px !important;
}

.div-ver-mas-actividad-card {
  height: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  min-height: 100px !important;
  padding: 10px !important;
}

.titulo-actividad-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  margin: unset !important;
  line-height: 25px !important;
}

.ver-mas-actividad-card {
  background: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: unset !important;
  width: 150px !important;
  transition: 0.2s all ease-out !important;
}

.ver-mas-actividad-card:hover {
  color: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

.modal-actividad-card .ant-modal-content {
  background: transparent !important;
  box-shadow: unset !important;
}

.modal-actividad-card .ant-modal-content .ant-modal-footer {
  padding: unset !important;
  text-align: unset !important;
  background: unset !important;
  border-top: unset !important;
  border-radius: unset !important;
}

.modal-actividad-card .slick-dots li.slick-active button {
  background: rgb(244, 220, 43, 1) !important;
}

.modal-actividad-card .slick-dots li button {
  background: black !important;
}

@media only screen and (max-width: 1000px) {
  .row-actividad-card {
    width: 90% !important;
  }

  .div-titulo-actividad-card {
    align-items: center !important;
  }

  .div-ver-mas-actividad-card {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .row-actividad-card {
    width: 90% !important;
  }

  .div-titulo-actividad-card {
    align-items: center !important;
  }

  .div-ver-mas-actividad-card {
    align-items: flex-start !important;
  }
}

.actividad-detalle-card {
text-align: center !important;
}

.foto-actividad-detalle-card {
  width: 100% !important;
  height: 85vh !important;
  object-fit: cover!important;
  border-radius: 10px !important;
}

.actividad-detalle-card .ant-skeleton-image {
  background: unset !important;
  height: 85vh !important;
}

.appbar {
  background: rgb(34, 47, 75, 1) !important;
  padding: 20px 4% 20px 4% !important;
  box-shadow: unset !important;
}

.appbar .MuiToolbar-root {
  justify-content: space-between !important;
}

.logo-appbar {
  width: 300px !important;
}

.item-appbar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
}

.item-appbar a {
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
}

.item-appbar:hover {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
  background: rgb(244, 220, 43, 1) !important;
  border-radius: 10px !important;
}

.item-appbar a:hover {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
}

.item-active-appbar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
  background: rgb(244, 220, 43, 1) !important;
  border-radius: 10px !important;
}

.item-active-appbar a {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
}

.item-movil-appbar h2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  width: 100% !important;
  border-radius: 10px !important;
  padding: 5px !important;
}

.item-movil-active-appbar h2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  width: 100% !important;
  border-radius: 10px !important;
  padding: 5px !important;
}

.ant-dropdown {
  top: 90px !important;
}

@media only screen and (max-width: 1000px) {
  .appbar {
    padding: 10px 0px !important;
  }

  .logo-appbar {
    width: 200px !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .appbar {
    padding: 10px 0px !important;
  }

  .logo-appbar {
    width: 200px !important;
  }
}

.error-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}

.error-page img {
  height: 50% !important;
  width: 50% !important;
}
/* 
.nodata {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.nodata img {
  height: 50% !important;
  width: 50% !important;
} */

.footer {
  background: rgb(34, 47, 75, 1) !important;
  padding: 29px 5% 29px 5% !important;
  position: relative !important;
  text-align: center !important;
  border-top: 5px solid rgb(244, 220, 43, 1) !important;
}

.footer .ant-row {
  justify-content: center !important;
  align-items: center !important;
}

.footer .ant-col {
  text-align: center !important;
}

.footer h3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(255, 255, 255, 1) !important;
  text-decoration: none !important;
}

.item-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(255, 255, 255, 1) !important;
  text-decoration: none !important;
}

.item-footer:hover {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(244, 220, 43, 1) !important;
  text-decoration: none !important;
}

.item-active-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(244, 220, 43, 1) !important;
  text-decoration: none !important;
}

.icon-siguenos-footer {
  font-size: 30px !important;
  margin: 0px 10px !important;
  color: rgb(244, 220, 43, 1) !important;
}

.icon-contacto-footer {
  font-size: 15px !important;
  color: rgb(244, 220, 43, 1) !important;
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 20px !important;
  }

  .footer .ant-col {
    margin: 0px 0px 10px 0px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .footer {
    padding: 20px !important;
  }

  .footer .ant-col {
    margin: 0px 0px 10px 0px !important;
  }
}

.load-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}

.nodata {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.nodata img {
  height: 50% !important;
  width: 50% !important;
}

.subappbar {
  background: rgb(244, 220, 43, 1) !important;
  padding: 10px 6% 10px 6% !important;
  text-align: end !important;
  justify-content: right !important;
}

.subappbar h3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  margin: unset !important;
}

/* .input-search-subappbar {
  background: rgb(255, 255, 255, 1) !important;
  border-radius: 10px !important;
  font-family: Carlito !important;
  padding: unset !important;
} */

.subappbar .ant-input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.subappbar .ant-input-affix-wrapper {
  outline: none !important;
  border: none !important;
  border-radius: 10px 0px 0px 10px !important;
  height: 30px !important;
}

.subappbar .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.subappbar .ant-input-group-addon {
  background: rgb(244, 220, 43, 1) !important;
  height: 30px !important;
  border-radius: 0px 10px 10px 0px !important;
}

.subappbar .ant-input-group-addon button {
  border: unset !important;
  border-radius: 0px 10px 10px 0px !important;
  background: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  box-shadow: unset !important;
  color: rgb(34, 47, 75, 1) !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.input-search-subappbar .MuiSvgIcon-root {
  color: rgb(34, 47, 75, 1) !important;
}

.input-search-subappbar fieldset {
  border: none !important;
  font-family: Carlito !important;
}

.input-search-subappbar input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.phone-subappbar {
  background: rgb(34, 47, 75, 1) !important;
  color: rgb(244, 220, 43, 1) !important;
  padding: 5px !important;
  border-radius: 10px !important;
  width: 200px !important;
  height: 30px !important;
}

@media only screen and (max-width: 600px) {
  .subappbar {
    text-align: center !important;
    padding: unset !important;
  }

  .phone-subappbar {
    width: 100px !important;
  }

  .subappbar .ant-col {
    padding: 2px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .subappbar {
    text-align: center !important;
    padding: unset !important;
  }

  .subappbar .ant-col {
    padding: 5px 10px !important;
  }
}

.subfooter {
  background: rgb(34, 47, 75, 1) !important;
  padding: 20px 10% 20px 10% !important;
  text-align: end !important;
}

.subfooter .ant-col {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.subfooter h3 {
  text-align: right !important;
  line-height: normal !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
}

.subfooter .ant-input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.subfooter .ant-input-affix-wrapper {
  outline: none !important;
  border: none !important;
  border-radius: 10px 0px 0px 10px !important;
  height: 30px !important;
}

.subfooter .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.subfooter .ant-input-group-addon {
  background: rgb(244, 220, 43, 1) !important;
  height: 30px !important;
  border-radius: 0px 10px 10px 0px !important;
}

.subfooter .ant-input-group-addon button {
  border: unset !important;
  border-radius: 0px 10px 10px 0px !important;
  background: rgb(244, 220, 43, 1) !important;
  font-family: CarlitoBold !important;
  box-shadow: unset !important;
  color: rgb(34, 47, 75, 1) !important;
  height: 30px !important;
}

.logo-subfooter {
  width: 250px !important;
}

@media only screen and (max-width: 600px) {
  .subfooter {
    text-align: center !important;
    padding: 20px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .subfooter {
    text-align: center !important;
    padding: 20px !important;
  }
}

.bolsa-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-bolsa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.subtitulo-bolsa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Carlito !important;
  margin: auto !important;
}

.banner-bolsa-page {
  width: 90% !important;
  margin: 15px auto !important;
  border-radius: 10px !important;
}

.form-bolsa-page {
  width: 90% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.form-bolsa-page h3 {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: unset !important;
}

.form-bolsa-page input {
  border-radius: 20px !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: Carlito !important;
}

.custom-label-bolsa-page {
  display: block !important;
  padding: 5px !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: CarlitoBold !important;
}

.form-bolsa-page button {
  background: rgb(255, 255, 255, 0) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
}

.form-bolsa-page button:hover {
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}

.catalogo-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-catalogo-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.categoria-card {
  width: 90% !important;
  border: unset !important;
  margin: auto auto 20px auto !important;
  cursor: pointer !important;
}

.categoria-card img {
  border-radius: 5px !important;
}

.categoria-card .ant-card-body {
  padding: 24px 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.categoria-card .ant-card-meta-title {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
}

.categoria-card .ant-card-body .MuiSvgIcon-root {
  color: rgb(244, 220, 43, 1) !important;
}

.categoria-card .ant-skeleton-image {
  height: 204px !important;
}

.titulo-inicio-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2rem !important;
  text-align: center !important;
}

.inicio-page .ant-carousel .slick-dots-bottom {
  bottom: -25px !important;
}

.col-carousel-inicio-page {
  min-height: 70vh !important;
  text-align: center !important;
}

.col-marcas-inicio-page {
  margin: 80px auto !important;
  padding: 0px 100px 0px 100px !important;
}

.col-marcas-inicio-page button {
  background: unset !important;
  box-shadow: none !important;
  color: rgb(34, 47, 75, 1) !important;
}

.col-carousel-inicio-page .slick-dots li.slick-active button {
  background: rgb(34, 47, 75, 1) !important;
}

.col-carousel-inicio-page .slick-dots li button {
  background: grey !important;
}

.inicio-page .ant-skeleton-image {
  height: 75vh !important;
}

@media only screen and (max-width: 1000px) {
  .col-marcas-inicio-page {
    margin: 40px auto !important;
    padding: unset !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .col-marcas-inicio-page {
    margin: 40px auto !important;
    padding: unset !important;
  }
}

.foto-marca-card {
  width: 80% !important;
  height: 50px !important;
  object-fit: contain !important;
}

.foto-marca-card .ant-skeleton-image {
  background: unset !important;
  height: 50px !important;
}

@media only screen and (max-width: 1000px) {
  .foto-marca-card {
    width: 85% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .foto-marca-card {
    width: 85% !important;
  }
}

.col-promocion-card {
  text-align: center !important;
}

.imagen-promocion-card {
  margin: auto !important;
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.ver-mas-promocion-card {
  background: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 20px auto !important;
  width: 150px !important;
  transition: 0.2s all ease-out !important;
}

.ver-mas-promocion-card:hover {
  color: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

.col-promocion-card .ant-skeleton-image {
  height: 350px !important;
}

.col-promocion-detalle-card {
  text-align: center !important;
}

.col-promocion-detalle-card .ant-skeleton-image {
  background: unset !important;
  height: 300px !important;
}

.imagen-promocion-detalle-card {
  margin: auto !important;
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
}

.titulo-promocion-detalle-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 0px auto 20px auto !important;
  font-size: 1.5rem !important;
  text-align: center !important;
}

.descripcion-promocion-detalle-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Gotham !important;
  margin: 0px auto 20px auto !important;
  text-align: justify !important;
  font-weight: bold !important;
}

.image-banner-card {
  height: 75vh;
  width: 100%;
  object-fit: fill;
}

/* Estilos específicos para dispositivos con un ancho máximo de 768px, típicamente para tablets y celulares en modo horizontal */
@media only screen and (max-width: 600px) {
  .image-banner-card {
    height: 75vh;
    width: 100%;
    object-fit: cover;
    object-position: right;
  }
}

.tienda-card {
  width: 90% !important;
  border: unset !important;
  margin: auto auto 20px auto !important;
}

.tienda-card img {
  border-radius: 5px !important;
}

.tienda-card .ant-card-body {
  padding: 24px 0px !important;
}

.tienda-card .ant-card-meta-title {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
}

.tienda-card .ant-card-meta-description a,
.tienda-card .ant-card-meta-description P {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  line-height: normal !important;
}

.tienda-card .ant-card-meta-description .MuiSvgIcon-root {
  font-size: 15px !important;
}

.tienda-card .ant-skeleton-image {
  height: 232px !important;
}

.titulo-nosotros-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.subtitulo-nosotros-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 1.5rem !important;
  border-bottom: 2.5px solid rgb(244, 220, 43, 1) !important;
  text-align: center !important;
}

.texto-nosotros-page {
  text-align: justify !important;
  font-family: Carlito !important;
  line-height: 20px !important;
  color: rgb(34, 47, 75, 1) !important;
}

.div-banner-nosotros-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.banner-nosotros-page {
  width: 90% !important;
  border-radius: 10px !important;
}

.col-icon-valores-nosotros-page {
  text-align: center !important;
}

.col-icon-valores-nosotros-page h3 {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  margin: 0px auto 50px auto !important;
}

.icon-valores-nosotros-page {
  width: 40% !important;
}

@media only screen and (max-width: 1000px) {
  .subtitulo-nosotros-page {
    margin: 20px !important;
  }

  .texto-nosotros-page {
    margin: 20px !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .subtitulo-nosotros-page {
    margin: 20px !important;
  }

  .texto-nosotros-page {
    margin: 20px !important;
  }
}

.content-notfound-page {
  text-align: center !important;
}

.notfound-page {
  text-align: center !important;
  font-family: GothamBold !important;
  font-size: 4rem !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
  margin: unset !important;
}

.logo-notfound-page {
  width: 250px !important;
  height: 250px !important;
  margin: 10px auto !important;
}

.titulo-notfound-page {
  text-align: center !important;
  font-family: GothamBold !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
}

.texto-notfound-page {
  text-align: center !important;
  font-family: Gotham !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: auto 20px !important;
}

.div-icons-notfound-page {
  padding: 15px !important;
  margin: 15px auto 15px auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: rgb(34, 47, 75, 1) !important;
}

.div-icon-notfound-page {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  margin: 5px 10px 5px 10px !important;
  padding: 5px !important;
  box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  color: rgb(34, 47, 75, 1) !important;
}

.icon-notfound-page {
  color: rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
}

.opinion-page .ant-col {
  padding: 5px 0px 5px 0px !important;
}

.opinion-page .ant-form-inline .ant-form-item-with-help {
  margin: unset;
}

.opinion-page .ant-form-inline .ant-form-item {
  margin: unset;
}

.titulo-opinion-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 0px auto !important;
  font-size: 2.5rem !important;
}

.texto-opinion-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Carlito !important;
  margin: 0px auto !important;
  width: 40% !important;
  font-size: 1.2rem !important;
  line-height: normal !important;
}

.form-opinion-page {
  width: 50% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.form-opinion-page h3 {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: unset !important;
  text-align: left !important;
  line-height: normal !important;
}

.form-opinion-page input,
.form-opinion-page textarea,
.form-opinion-page .ant-select-selector {
  border-radius: 20px !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: Carlito !important;
  resize: none !important;
}

.form-opinion-page .ant-select-selector input {
  background: transparent !important;
}

.form-opinion-page textarea {
  height: 120px !important;
}

.form-opinion-page button {
  background: rgb(255, 255, 255, 0) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  transition: 0.2s all ease-out !important;
}

.form-opinion-page button:hover {
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

@media only screen and (max-width: 1000px) {
  .texto-opinion-page {
    width: 90% !important;
  }

  .form-opinion-page {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .texto-opinion-page {
    width: 90% !important;
  }
}

.postventa-page .ant-col {
  padding: 5px 0px 5px 0px !important;
}

.postventa-page .ant-form-inline .ant-form-item-with-help {
  margin: unset;
}

.postventa-page .ant-form-inline .ant-form-item {
  margin: unset;
}

.titulo-postventa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.subtitulo-postventa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  margin: auto !important;
  border-bottom: 5px solid rgb(244, 220, 43, 1) !important;
  width: 200px !important;
}

.texto-postventa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Carlito !important;
  margin: 15px auto !important;
  width: 40% !important;
  font-size: 1.2rem !important;
  line-height: normal !important;
}

.form-postventa-page {
  width: 50% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.form-postventa-page h3 {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: unset !important;
}

.form-postventa-page input,
.form-postventa-page textarea {
  border-radius: 20px !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: Carlito !important;
  resize: none !important;
}

.form-postventa-page textarea {
  height: 100px !important;
}

.form-postventa-page button {
  background: rgb(255, 255, 255, 0) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  transition: 0.2s all ease-out !important;
}

.form-postventa-page button:hover {
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

@media only screen and (max-width: 1000px) {
  .texto-postventa-page {
    width: 90% !important;
  }

  .form-postventa-page {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .texto-postventa-page {
    width: 90% !important;
  }
}

.productos-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-productos-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  text-align: left !important;
  font-size: 2.5rem !important;
}

.subtitulo-productos-page {
  background: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  text-align: center !important;
  color: rgb(255, 255, 255, 1) !important;
  padding: 5px !important;
  margin: 10px auto !important;
  border-radius: 10px !important;
}

.row-listaproductos-productos-page {
  min-height: 500px !important;
}

.col-collapse-productos-page .ant-collapse {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-item {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-header {
  background: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  text-align: left !important;
  color: rgb(255, 255, 255, 1) !important;
  border-radius: 10px !important;
  border: unset !important;
  height: 30px !important;
  padding: 5px 5px 5px 10px !important;
}

.col-pagination-productos-page {
  text-align: right !important;
  padding: 20px 0px 20px 0px !important;
}

.col-collapse-productos-page .ant-collapse-content-active {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-content-box {
  background: rgb(158, 158, 158, 0.48) !important;
  margin: 5px auto !important;
  border-radius: 10px !important;
  text-align: left !important;
}

.col-collapse-productos-page .ant-checkbox-group {
  text-align: left !important;
  font-family: CarlitoBold !important;
}

.col-button-productos-page {
  padding: 10px !important;
}

.button-drop-filter-productos-page {
  border-radius: 10px !important;
}

@media only screen and (max-width: 1000px) {
  .titulo-productos-page {
    margin: 10px !important;
    text-align: center !important;
  }

  .subtitulo-productos-page {
    margin: 10px !important;
  }

  .col-collapse-productos-page .ant-collapse {
    margin: 10px !important;
  }

  .col-pagination-productos-page {
    text-align: center !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .titulo-productos-page {
    margin: 10px !important;
    text-align: center !important;
  }

  .subtitulo-productos-page {
    margin: 10px !important;
  }

  .col-collapse-productos-page .ant-collapse {
    margin: 10px !important;
  }

  .col-pagination-productos-page {
    text-align: center !important;
  }
}

.card-producto-card {
  border-radius: 10px !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  box-shadow: 1px 1px 2px 0px rgba(122, 114, 114, 1) !important;
  -webkit-box-shadow: 1px 1px 2px 0px rgb(158, 158, 158, 1) !important;
  -moz-box-shadow: 1px 1px 2px 0px rgb(158, 158, 158, 1) !important;
  min-height: 520px !important;
  height: 510px !important;
  margin: 10px !important;
  cursor: pointer !important;
}

.card-producto-card .MuiChip-root {
  font-size: 10px !important;
}

.col-foto-producto-card {
  height: 250px !important;
}

.marca-producto-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 0px auto !important;
}

.nombre-producto-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Gotham !important;
  margin: 0px auto !important;
}

.col-colores-producto-card {
  margin: 5px 2.5px 2.5px 5px !important;
}

.col-sizes-producto-card {
  padding: 0px 10px !important;
}

.col-sizes-producto-card .ant-col {
  padding: 2px !important;
}

.col-modelos-producto-card {
  padding: 0px 10px !important;
}

.col-modelos-producto-card .ant-col {
  padding: 2px !important;
}

.col-weight-producto-card {
  padding: 0px 10px !important;
}

.col-weight-producto-card .ant-col {
  padding: 2px !important;
}

.card-producto-card .ant-carousel .slick-dots-bottom {
  bottom: -30px !important;
}

.card-producto-card .slick-dots li.slick-active button {
  background: rgb(244, 220, 43, 1) !important;
}

.card-producto-card .slick-dots li button {
  background: grey !important;
}

.card-producto-card .ant-skeleton-image {
  background: unset !important;
  height: 220px !important;
}

.image-fotoproducto-card {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
  object-fit: cover !important;
}

.imagespin-fotoproducto-card {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
}

.imagespin-fotoproducto-card:hover {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.titulo-proyectos-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.construccion-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.construccion-page img {
  width: 30% !important;
}

.titulo-libro-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

@media only screen and (max-width: 1000px) {
  .titulo-libro-page {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .titulo-libro-page {
    font-size: 1rem !important;
  }
}
.titulo-responsabilidad-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

@media only screen and (max-width: 1000px) {
  .titulo-responsabilidad-page {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .titulo-responsabilidad-page {
    font-size: 1rem !important;
  }
}

.titulo-ubicanos-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

