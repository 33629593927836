.actividad-detalle-card {
text-align: center !important;
}

.foto-actividad-detalle-card {
  width: 100% !important;
  height: 85vh !important;
  object-fit: cover!important;
  border-radius: 10px !important;
}

.actividad-detalle-card .ant-skeleton-image {
  background: unset !important;
  height: 85vh !important;
}
