.opinion-page .ant-col {
  padding: 5px 0px 5px 0px !important;
}

.opinion-page .ant-form-inline .ant-form-item-with-help {
  margin: unset;
}

.opinion-page .ant-form-inline .ant-form-item {
  margin: unset;
}

.titulo-opinion-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 0px auto !important;
  font-size: 2.5rem !important;
}

.texto-opinion-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Carlito !important;
  margin: 0px auto !important;
  width: 40% !important;
  font-size: 1.2rem !important;
  line-height: normal !important;
}

.form-opinion-page {
  width: 50% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.form-opinion-page h3 {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: unset !important;
  text-align: left !important;
  line-height: normal !important;
}

.form-opinion-page input,
.form-opinion-page textarea,
.form-opinion-page .ant-select-selector {
  border-radius: 20px !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: Carlito !important;
  resize: none !important;
}

.form-opinion-page .ant-select-selector input {
  background: transparent !important;
}

.form-opinion-page textarea {
  height: 120px !important;
}

.form-opinion-page button {
  background: rgb(255, 255, 255, 0) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  transition: 0.2s all ease-out !important;
}

.form-opinion-page button:hover {
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

@media only screen and (max-width: 1000px) {
  .texto-opinion-page {
    width: 90% !important;
  }

  .form-opinion-page {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .texto-opinion-page {
    width: 90% !important;
  }
}
