@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
/* ================ HTML ================ */
@font-face {
  font-family: Carlito;
  src: url(../fonts/Carlito-Regular.ttf);
}

@font-face {
  font-family: CarlitoBold;
  src: url(../fonts/Carlito-Bold.ttf);
}

@font-face {
  font-family: GothamBold;
  src: url(../fonts/Gotham-Bold.ttf);
}

@font-face {
  font-family: Gotham;
  src: url(../fonts/Gotham-Light.otf);
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(34, 47, 75, 1) !important;
}
::-webkit-scrollbar-track {
  background: rgb(244, 220, 43, 1) !important;
}

.hide {
  display: none !important;
}

.visible {
  display: block !important;
}

/* ================ SECCIONES ================ */
.content {
  text-align: center !important;
  width: 75% !important;
  margin: auto !important;
  position: relative !important;
}

/* ================ ANTD ================ */
/* Card */
.ant-card {
  text-align: center !important;
}

/* Row */
.ant-row {
  justify-content: center !important;
}

/* Skeleton */
.ant-skeleton-image {
  background: unset !important;
}


/* Modal */
.ant-modal-wrap {
  z-index: 1100 !important;
}

/* ================ MEDIA QUERY ================ */
@media only screen and (max-width: 1000px) {
  .content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .content {
    width: 90% !important;
  }
}
