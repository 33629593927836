.tienda-card {
  width: 90% !important;
  border: unset !important;
  margin: auto auto 20px auto !important;
}

.tienda-card img {
  border-radius: 5px !important;
}

.tienda-card .ant-card-body {
  padding: 24px 0px !important;
}

.tienda-card .ant-card-meta-title {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
}

.tienda-card .ant-card-meta-description a,
.tienda-card .ant-card-meta-description P {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  line-height: normal !important;
}

.tienda-card .ant-card-meta-description .MuiSvgIcon-root {
  font-size: 15px !important;
}

.tienda-card .ant-skeleton-image {
  height: 232px !important;
}
