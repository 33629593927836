.nodata {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.nodata img {
  height: 50% !important;
  width: 50% !important;
}
