.image-fotoproducto-card {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
  object-fit: cover !important;
}

.imagespin-fotoproducto-card {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
}

.imagespin-fotoproducto-card:hover {
  width: 220px !important;
  height: 220px !important;
  margin: auto !important;
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
