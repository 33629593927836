.footer {
  background: rgb(34, 47, 75, 1) !important;
  padding: 29px 5% 29px 5% !important;
  position: relative !important;
  text-align: center !important;
  border-top: 5px solid rgb(244, 220, 43, 1) !important;
}

.footer .ant-row {
  justify-content: center !important;
  align-items: center !important;
}

.footer .ant-col {
  text-align: center !important;
}

.footer h3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(255, 255, 255, 1) !important;
  text-decoration: none !important;
}

.item-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(255, 255, 255, 1) !important;
  text-decoration: none !important;
}

.item-footer:hover {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(244, 220, 43, 1) !important;
  text-decoration: none !important;
}

.item-active-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: Carlito !important;
  margin: unset !important;
  color: rgb(244, 220, 43, 1) !important;
  text-decoration: none !important;
}

.icon-siguenos-footer {
  font-size: 30px !important;
  margin: 0px 10px !important;
  color: rgb(244, 220, 43, 1) !important;
}

.icon-contacto-footer {
  font-size: 15px !important;
  color: rgb(244, 220, 43, 1) !important;
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 20px !important;
  }

  .footer .ant-col {
    margin: 0px 0px 10px 0px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .footer {
    padding: 20px !important;
  }

  .footer .ant-col {
    margin: 0px 0px 10px 0px !important;
  }
}
