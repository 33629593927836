.col-promocion-card {
  text-align: center !important;
}

.imagen-promocion-card {
  margin: auto !important;
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.ver-mas-promocion-card {
  background: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 20px auto !important;
  width: 150px !important;
  transition: 0.2s all ease-out !important;
}

.ver-mas-promocion-card:hover {
  color: rgb(34, 47, 75, 1) !important;
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

.col-promocion-card .ant-skeleton-image {
  height: 350px !important;
}
