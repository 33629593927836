.bolsa-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-bolsa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.subtitulo-bolsa-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Carlito !important;
  margin: auto !important;
}

.banner-bolsa-page {
  width: 90% !important;
  margin: 15px auto !important;
  border-radius: 10px !important;
}

.form-bolsa-page {
  width: 90% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.form-bolsa-page h3 {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: unset !important;
}

.form-bolsa-page input {
  border-radius: 20px !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: Carlito !important;
}

.custom-label-bolsa-page {
  display: block !important;
  padding: 5px !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  background: rgb(217, 217, 217, 63%) !important;
  font-family: CarlitoBold !important;
}

.form-bolsa-page button {
  background: rgb(255, 255, 255, 0) !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  border-radius: 10px !important;
}

.form-bolsa-page button:hover {
  border: 1px solid rgb(244, 220, 43, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  transform: scale(1.1) !important;
  font-family: GothamBold !important;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}
