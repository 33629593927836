.construccion-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

.construccion-page img {
  width: 30% !important;
}
