.titulo-inicio-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2rem !important;
  text-align: center !important;
}

.inicio-page .ant-carousel .slick-dots-bottom {
  bottom: -25px !important;
}

.col-carousel-inicio-page {
  min-height: 70vh !important;
  text-align: center !important;
}

.col-marcas-inicio-page {
  margin: 80px auto !important;
  padding: 0px 100px 0px 100px !important;
}

.col-marcas-inicio-page button {
  background: unset !important;
  box-shadow: none !important;
  color: rgb(34, 47, 75, 1) !important;
}

.col-carousel-inicio-page .slick-dots li.slick-active button {
  background: rgb(34, 47, 75, 1) !important;
}

.col-carousel-inicio-page .slick-dots li button {
  background: grey !important;
}

.inicio-page .ant-skeleton-image {
  height: 75vh !important;
}

@media only screen and (max-width: 1000px) {
  .col-marcas-inicio-page {
    margin: 40px auto !important;
    padding: unset !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .col-marcas-inicio-page {
    margin: 40px auto !important;
    padding: unset !important;
  }
}
