.col-promocion-detalle-card {
  text-align: center !important;
}

.col-promocion-detalle-card .ant-skeleton-image {
  background: unset !important;
  height: 300px !important;
}

.imagen-promocion-detalle-card {
  margin: auto !important;
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
}

.titulo-promocion-detalle-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 0px auto 20px auto !important;
  font-size: 1.5rem !important;
  text-align: center !important;
}

.descripcion-promocion-detalle-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Gotham !important;
  margin: 0px auto 20px auto !important;
  text-align: justify !important;
  font-weight: bold !important;
}
