.titulo-libro-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

@media only screen and (max-width: 1000px) {
  .titulo-libro-page {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .titulo-libro-page {
    font-size: 1rem !important;
  }
}