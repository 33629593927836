.subappbar {
  background: rgb(244, 220, 43, 1) !important;
  padding: 10px 6% 10px 6% !important;
  text-align: end !important;
  justify-content: right !important;
}

.subappbar h3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  margin: unset !important;
}

/* .input-search-subappbar {
  background: rgb(255, 255, 255, 1) !important;
  border-radius: 10px !important;
  font-family: Carlito !important;
  padding: unset !important;
} */

.subappbar .ant-input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.subappbar .ant-input-affix-wrapper {
  outline: none !important;
  border: none !important;
  border-radius: 10px 0px 0px 10px !important;
  height: 30px !important;
}

.subappbar .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.subappbar .ant-input-group-addon {
  background: rgb(244, 220, 43, 1) !important;
  height: 30px !important;
  border-radius: 0px 10px 10px 0px !important;
}

.subappbar .ant-input-group-addon button {
  border: unset !important;
  border-radius: 0px 10px 10px 0px !important;
  background: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
  box-shadow: unset !important;
  color: rgb(34, 47, 75, 1) !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.input-search-subappbar .MuiSvgIcon-root {
  color: rgb(34, 47, 75, 1) !important;
}

.input-search-subappbar fieldset {
  border: none !important;
  font-family: Carlito !important;
}

.input-search-subappbar input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.phone-subappbar {
  background: rgb(34, 47, 75, 1) !important;
  color: rgb(244, 220, 43, 1) !important;
  padding: 5px !important;
  border-radius: 10px !important;
  width: 200px !important;
  height: 30px !important;
}

@media only screen and (max-width: 600px) {
  .subappbar {
    text-align: center !important;
    padding: unset !important;
  }

  .phone-subappbar {
    width: 100px !important;
  }

  .subappbar .ant-col {
    padding: 2px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .subappbar {
    text-align: center !important;
    padding: unset !important;
  }

  .subappbar .ant-col {
    padding: 5px 10px !important;
  }
}
