.categoria-card {
  width: 90% !important;
  border: unset !important;
  margin: auto auto 20px auto !important;
  cursor: pointer !important;
}

.categoria-card img {
  border-radius: 5px !important;
}

.categoria-card .ant-card-body {
  padding: 24px 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.categoria-card .ant-card-meta-title {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
}

.categoria-card .ant-card-body .MuiSvgIcon-root {
  color: rgb(244, 220, 43, 1) !important;
}

.categoria-card .ant-skeleton-image {
  height: 204px !important;
}
