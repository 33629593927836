.card-producto-card {
  border-radius: 10px !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  box-shadow: 1px 1px 2px 0px rgba(122, 114, 114, 1) !important;
  -webkit-box-shadow: 1px 1px 2px 0px rgb(158, 158, 158, 1) !important;
  -moz-box-shadow: 1px 1px 2px 0px rgb(158, 158, 158, 1) !important;
  min-height: 520px !important;
  height: 510px !important;
  margin: 10px !important;
  cursor: pointer !important;
}

.card-producto-card .MuiChip-root {
  font-size: 10px !important;
}

.col-foto-producto-card {
  height: 250px !important;
}

.marca-producto-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 0px auto !important;
}

.nombre-producto-card {
  color: rgb(34, 47, 75, 1) !important;
  font-family: Gotham !important;
  margin: 0px auto !important;
}

.col-colores-producto-card {
  margin: 5px 2.5px 2.5px 5px !important;
}

.col-sizes-producto-card {
  padding: 0px 10px !important;
}

.col-sizes-producto-card .ant-col {
  padding: 2px !important;
}

.col-modelos-producto-card {
  padding: 0px 10px !important;
}

.col-modelos-producto-card .ant-col {
  padding: 2px !important;
}

.col-weight-producto-card {
  padding: 0px 10px !important;
}

.col-weight-producto-card .ant-col {
  padding: 2px !important;
}

.card-producto-card .ant-carousel .slick-dots-bottom {
  bottom: -30px !important;
}

.card-producto-card .slick-dots li.slick-active button {
  background: rgb(244, 220, 43, 1) !important;
}

.card-producto-card .slick-dots li button {
  background: grey !important;
}

.card-producto-card .ant-skeleton-image {
  background: unset !important;
  height: 220px !important;
}
