.foto-marca-card {
  width: 80% !important;
  height: 50px !important;
  object-fit: contain !important;
}

.foto-marca-card .ant-skeleton-image {
  background: unset !important;
  height: 50px !important;
}

@media only screen and (max-width: 1000px) {
  .foto-marca-card {
    width: 85% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .foto-marca-card {
    width: 85% !important;
  }
}
