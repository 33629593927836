.image-banner-card {
  height: 75vh;
  width: 100%;
  object-fit: fill;
}

/* Estilos específicos para dispositivos con un ancho máximo de 768px, típicamente para tablets y celulares en modo horizontal */
@media only screen and (max-width: 600px) {
  .image-banner-card {
    height: 75vh;
    width: 100%;
    object-fit: cover;
    object-position: right;
  }
}
