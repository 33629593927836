.productos-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-productos-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  text-align: left !important;
  font-size: 2.5rem !important;
}

.subtitulo-productos-page {
  background: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  text-align: center !important;
  color: rgb(255, 255, 255, 1) !important;
  padding: 5px !important;
  margin: 10px auto !important;
  border-radius: 10px !important;
}

.row-listaproductos-productos-page {
  min-height: 500px !important;
}

.col-collapse-productos-page .ant-collapse {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-item {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-header {
  background: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  text-align: left !important;
  color: rgb(255, 255, 255, 1) !important;
  border-radius: 10px !important;
  border: unset !important;
  height: 30px !important;
  padding: 5px 5px 5px 10px !important;
}

.col-pagination-productos-page {
  text-align: right !important;
  padding: 20px 0px 20px 0px !important;
}

.col-collapse-productos-page .ant-collapse-content-active {
  border: unset !important;
}

.col-collapse-productos-page .ant-collapse-content-box {
  background: rgb(158, 158, 158, 0.48) !important;
  margin: 5px auto !important;
  border-radius: 10px !important;
  text-align: left !important;
}

.col-collapse-productos-page .ant-checkbox-group {
  text-align: left !important;
  font-family: CarlitoBold !important;
}

.col-button-productos-page {
  padding: 10px !important;
}

.button-drop-filter-productos-page {
  border-radius: 10px !important;
}

@media only screen and (max-width: 1000px) {
  .titulo-productos-page {
    margin: 10px !important;
    text-align: center !important;
  }

  .subtitulo-productos-page {
    margin: 10px !important;
  }

  .col-collapse-productos-page .ant-collapse {
    margin: 10px !important;
  }

  .col-pagination-productos-page {
    text-align: center !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .titulo-productos-page {
    margin: 10px !important;
    text-align: center !important;
  }

  .subtitulo-productos-page {
    margin: 10px !important;
  }

  .col-collapse-productos-page .ant-collapse {
    margin: 10px !important;
  }

  .col-pagination-productos-page {
    text-align: center !important;
  }
}
