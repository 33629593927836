.titulo-nosotros-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}

.subtitulo-nosotros-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 1.5rem !important;
  border-bottom: 2.5px solid rgb(244, 220, 43, 1) !important;
  text-align: center !important;
}

.texto-nosotros-page {
  text-align: justify !important;
  font-family: Carlito !important;
  line-height: 20px !important;
  color: rgb(34, 47, 75, 1) !important;
}

.div-banner-nosotros-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.banner-nosotros-page {
  width: 90% !important;
  border-radius: 10px !important;
}

.col-icon-valores-nosotros-page {
  text-align: center !important;
}

.col-icon-valores-nosotros-page h3 {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
  margin: 0px auto 50px auto !important;
}

.icon-valores-nosotros-page {
  width: 40% !important;
}

@media only screen and (max-width: 1000px) {
  .subtitulo-nosotros-page {
    margin: 20px !important;
  }

  .texto-nosotros-page {
    margin: 20px !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .subtitulo-nosotros-page {
    margin: 20px !important;
  }

  .texto-nosotros-page {
    margin: 20px !important;
  }
}
