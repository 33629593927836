.subfooter {
  background: rgb(34, 47, 75, 1) !important;
  padding: 20px 10% 20px 10% !important;
  text-align: end !important;
}

.subfooter .ant-col {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.subfooter h3 {
  text-align: right !important;
  line-height: normal !important;
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
}

.subfooter .ant-input {
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
}

.subfooter .ant-input-affix-wrapper {
  outline: none !important;
  border: none !important;
  border-radius: 10px 0px 0px 10px !important;
  height: 30px !important;
}

.subfooter .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.subfooter .ant-input-group-addon {
  background: rgb(244, 220, 43, 1) !important;
  height: 30px !important;
  border-radius: 0px 10px 10px 0px !important;
}

.subfooter .ant-input-group-addon button {
  border: unset !important;
  border-radius: 0px 10px 10px 0px !important;
  background: rgb(244, 220, 43, 1) !important;
  font-family: CarlitoBold !important;
  box-shadow: unset !important;
  color: rgb(34, 47, 75, 1) !important;
  height: 30px !important;
}

.logo-subfooter {
  width: 250px !important;
}

@media only screen and (max-width: 600px) {
  .subfooter {
    text-align: center !important;
    padding: 20px !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .subfooter {
    text-align: center !important;
    padding: 20px !important;
  }
}
