.appbar {
  background: rgb(34, 47, 75, 1) !important;
  padding: 20px 4% 20px 4% !important;
  box-shadow: unset !important;
}

.appbar .MuiToolbar-root {
  justify-content: space-between !important;
}

.logo-appbar {
  width: 300px !important;
}

.item-appbar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
}

.item-appbar a {
  color: rgb(255, 255, 255, 1) !important;
  font-family: CarlitoBold !important;
}

.item-appbar:hover {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
  background: rgb(244, 220, 43, 1) !important;
  border-radius: 10px !important;
}

.item-appbar a:hover {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
}

.item-active-appbar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px 15px 0px 15px !important;
  padding: 0px 5px 0px 5px !important;
  background: rgb(244, 220, 43, 1) !important;
  border-radius: 10px !important;
}

.item-active-appbar a {
  color: rgb(34, 47, 75, 1) !important;
  font-family: CarlitoBold !important;
}

.item-movil-appbar h2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  width: 100% !important;
  border-radius: 10px !important;
  padding: 5px !important;
}

.item-movil-active-appbar h2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: CarlitoBold !important;
  color: rgb(34, 47, 75, 1) !important;
  background: rgb(244, 220, 43, 1) !important;
  width: 100% !important;
  border-radius: 10px !important;
  padding: 5px !important;
}

.ant-dropdown {
  top: 90px !important;
}

@media only screen and (max-width: 1000px) {
  .appbar {
    padding: 10px 0px !important;
  }

  .logo-appbar {
    width: 200px !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .appbar {
    padding: 10px 0px !important;
  }

  .logo-appbar {
    width: 200px !important;
  }
}
