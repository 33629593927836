.catalogo-page .ant-col {
  padding: 0px 10px 0px 10px !important;
}

.titulo-catalogo-page {
  color: rgb(34, 47, 75, 1) !important;
  font-family: GothamBold !important;
  margin: 20px auto 20px auto !important;
  font-size: 2.5rem !important;
}
