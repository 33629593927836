.content-notfound-page {
  text-align: center !important;
}

.notfound-page {
  text-align: center !important;
  font-family: GothamBold !important;
  font-size: 4rem !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
  margin: unset !important;
}

.logo-notfound-page {
  width: 250px !important;
  height: 250px !important;
  margin: 10px auto !important;
}

.titulo-notfound-page {
  text-align: center !important;
  font-family: GothamBold !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
}

.texto-notfound-page {
  text-align: center !important;
  font-family: Gotham !important;
  color: rgb(34, 47, 75, 1) !important;
  margin: auto 20px !important;
}

.div-icons-notfound-page {
  padding: 15px !important;
  margin: 15px auto 15px auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: rgb(34, 47, 75, 1) !important;
}

.div-icon-notfound-page {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  margin: 5px 10px 5px 10px !important;
  padding: 5px !important;
  box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  color: rgb(34, 47, 75, 1) !important;
}

.icon-notfound-page {
  color: rgb(34, 47, 75, 1) !important;
  color: rgb(34, 47, 75, 1) !important;
}
